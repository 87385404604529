<template lang="pug">
good-table(
  mode="remote"
  @on-page-change="onPageChange"
  @on-sort-change="onSortChange"
  :columns="fields"
  :rows="rows"
  :totalRows="totalRows"
  :sort-options="{ enabled: true, multipleColumns: false, }"
  :pagination-options="options"
)
  template(slot="table-row" slot-scope="{ column, row }")
    template(v-if="column.field === 'title'")
      span {{ row.title }}
    template(v-if="column.field === 'classType'")
      span {{ $t(`class_type_${row.classType}`) }}
      template(v-if="isShortDemo(row.classType)")
        //- 10分鐘的短DEMO
        span.badge.badge-danger 10
      template(v-else-if="isDemo(row.classType)")
        //- 目前 platform 暫時先用教材
        span(v-html="isJrMaterial(row.material.platform)? '(JR)': '(W)'")
        //- 25分鐘的DEMO
        span.badge.badge-warning 25
    template(v-if="column.field === 'status'")
      span {{ getConsultantStatus(row.consultantStatus) }}
    template(v-if="column.field === 'material'")
      span {{ row.material.title }}
    template(v-if="column.field === 'vip'")
      //- 目前platform 暫時先用教材
      vip-field(v-if="row.vips && row.vips[0]" :isJrClass="isJrClass(row.classType, row.material.platform)" :data="{ ...row, vip: row.vips[0] }" :beforeAnalysisDeadline="beforeAnalysisDeadline(row.vips[0].classTime)")
    //- 20220613移除vip abc
    //- template(v-if="column.field === 'vipA'")
    //-   vip-field(v-if="row.vips && row.vips[0]" :data="{ ...row, vip: row.vips[0] }")
    //- template(v-if="column.field === 'vipB'")
    //-   vip-field(v-if="row.vips && row.vips[1]" :data="{ ...row, vip: row.vips[1] }")
    //- template(v-if="column.field === 'vipC'")
    //-   vip-field(v-if="row.vips && row.vips[2]" :data="{ ...row, vip: row.vips[2] }")
    template(v-if="column.field === 'operation'")
      //-  operation只放填寫(或修改)的按鈕
      //- 條件: 必須是JR的課 且 必須是 vipStatus=show 且 ( 從來沒有填過評鑑(即使過期了) 或 在課程時間後24.5內都可以編輯 )
      //- 顏色: 綠=>有填寫過(filledAt不是空) 紅=>從來沒有填寫過
      div(v-if="(!row.vips[0].consultantVipAnalysis.filledAt || beforeAnalysisDeadline(row.vips[0].classTime)) && canWriteByStatus(row) && isJrClass(row.classType, row.material.platform)")
        router-link.btn(
          :to="{path: `/console/consultant/classRecords/addAnalysis/${row.classroomId}`}"
          :class="[!row.vips[0].consultantVipAnalysis.filledAt ? 'btn-danger' : 'btn-success']"
        )
          font-awesome-icon(icon="chart-bar")
      //- 2022/08/15 對照wuwow外部看起來沒有這個東西先註解掉 By Asa
      //- button.btn.btn-danger(
      //-   v-if="[5, 6].includes(row.vips.map(data => data.user.vipStatus)[0])"
      //-   @click="showWarning"
      //- )
      //-   font-awesome-icon(icon="chart-bar")
</template>

<script>
// import { alertInfoMessage } from '@/utils/sweetAlert.js';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable as GoodTable } from 'vue-good-table/dist/vue-good-table';
import vipField from './vipField/index.vue';
import classroomConstants from '@/constants/classroom';
import { isShortDemo, isDemo, isJrMaterial, isJrClass } from '@/utils/classroom';
import directToConsultantFeedback from '@/components/class/vipClassroom/directToConsultantFeedback';
import { mapState } from 'vuex';
import moment from 'moment';
import fields from './fields';
import i18n from './lang';

const DEFAULT_PER_PAGE = 10,
      FIRST_PAGE = 1,
      HIGH_RATE = 80,
      MEDIUM_RATE = 60;

export default {
  components: {
    GoodTable,
    vipField,
    directToConsultantFeedback,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  i18n,
  data: () => ({
    fields,
    options: {
      enabled: true,
      mode: 'pages',
      perPage: DEFAULT_PER_PAGE,
      perPageDropdownEnabled: false,
      jumpFirstOrLast: true,
    },
    serverParams: {
      page: FIRST_PAGE,
      perPage: DEFAULT_PER_PAGE,
      sort: null,
    },
  }),
  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
    rows() {
      if (!this.data) {
        return [];
      }
      return this.data.data.classRecords;
    },
    totalRows() {
      if (!this.data) {
        return 0;
      }
      return this.data.meta.pagination.total;
    },

  },
  methods: {
    // 2022/08/15 對照wuwow外部看起來沒有這個東西先註解掉 By Asa
    // async showWarning() {
    //   await alertInfoMessage(this.$t('warningMessage'));
    // },
    canWriteByStatus(data) {
      // 如果是Problem Demo Class => 顧問不用填寫評鑑
      if (data.status === classroomConstants.VIP_STATUS.PROBLEM && this.isDemo(data.classType)){
        return false;
      }
      // 如果學生未出席 => 顧問不用填寫評鑑
      if (data.vips[0].user.vipStatus !== classroomConstants.VIP_STATUS.SHOW){
        return false;
      }
      return true;
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.loadItems();
    },
    onSortChange(params) {
      // 取得第一個排序
      const sort = [...params].shift();
      this.serverParams.sort = `${sort.field}|${sort.type}`;

      if (sort.type === 'none') {
        this.serverParams.sort = null;
      }
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      const serverParams = Object.assign({}, {
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        sort: this.serverParams.sort,
      });
      this.$emit('load-data', serverParams);
    },
    onReturnListVariant(rating) {
      if (rating >= HIGH_RATE) {
        return 'success';
      } else if (rating >= MEDIUM_RATE) {
        return 'warning';
      } else {
        return 'danger';
      }
    },
    onCheckSuggest(data) {
      if (data.hbSuggestion ||
        (
          data.suggestionExpressTimeRating &&
          data.suggestionExpressTimeRating !== '0'
        ) ||
        data.suggestionOverallTimeRating ||
        data.suggestionAdviceRating
      ) {
        return true;
      } else {
        return false;
      }
    },
    beforeAnalysisDeadline(classTime){
      // 24.5小時內都可以新增編輯
      const allowAnalysis = 24.5;
      const lastAnalysisTime = moment(classTime).add(allowAnalysis, 'hours');
      const now = moment(this.currentTime).format('YYYY-MM-DD HH:mm:ss');
      return moment(now).isBefore(lastAnalysisTime);
    },

    getConsultantStatus(status){
      switch (status){
        case classroomConstants.CONSULTANT_STATUS.NO_SHOW:
          return this.$t('noShow');
        case classroomConstants.CONSULTANT_STATUS.SHOW:
          return this.$t('show');
        case classroomConstants.CONSULTANT_STATUS.LATE:
          return this.$t('late');
        case classroomConstants.CONSULTANT_STATUS.TARDY:
          return this.$t('tardy');
        case classroomConstants.CONSULTANT_STATUS.PROBLEM_NO_PAID:
        case classroomConstants.CONSULTANT_STATUS.PROBLEM_PAID:
          return this.$t('problem');
        default:
          return '-';
      }
    },
    isShortDemo,
    isDemo,
    isJrMaterial,
    isJrClass,
  },
};
</script>

<style lang="scss">
.vgt-global-search {
  border: 0;
  background: #fff;
  max-width: 100%;
  .vgt-global-search__input {
    max-width: 65%;
    width: 100%;
  }
  input {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 2px solid #ddd;
    border-radius: 0;
  }
  .input-field {
    border-bottom: 2px solid #ddd;
    margin: 0 0;
    width: 100%;
  }
  .vgt-global-search__actions {
    max-width: 25%;
    width: 100%;
  }
  select {
    -webkit-appearance: none;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 14px;
    display: inline-block;
    max-width: 85%;
    width: 100%;
    option {
      color: #999;
    }
  }
}

.vgt-table.bordered {
  border: 0;
  tr.clickable {
    border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #F5F5F5;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  thead {
    th {
      background: #fff;
    }
  }
  td {
    border-left: 0;
    border-right: 0;
    padding: 20px;
  }
  th {
    border: 0;
  }
  th.vgt-checkbox-col {
    background: #fff;
    input[type="checkbox"] {
      width: 22px;
      height: 22px;
      border: 2px solid #888;
      border-radius: 2px;
      -webkit-appearance: none;
      cursor: pointer;
      position: relative;
      display: inline-block;
      &:checked {
        background-color: #757575;
        border-color: #363636;
        &:after {
          content: '';
          display: block;
          width: 6px;
          height: 12px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          left: 25%;
        }
      }
    }
  }
}

.vgt-wrap__actions-footer, .vgt-wrap__footer {
  border: 0;
}

.smile-face-img{
  width: 25px;
}
</style>
