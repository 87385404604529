export default [
  {
    label: 'Classroom Title',
    field: 'title',
  },
  {
    label: 'Class Type',
    field: 'classType',
    sortable: false,
  },
  {
    label: 'Consultant Status',
    field: 'status',
    sortable: false,
  },
  {
    label: 'Material',
    field: 'material',
    sortable: false,
  },
  {
    label: 'VIP',
    field: 'vip',
    sortable: false,
  },
];
