<template lang="pug">
div
  p.m-0 {{ data.vip.user.englishName }}
  span.badge.badge-pill.badge-info
    span(v-if="data.status === classroomConstants.CLASSROOM_STATUS.PROBLEM && isDemo(data.classType)")
      | {{ $t('problemDemoClass') }}
    span(v-else-if="data.status === classroomConstants.CLASSROOM_STATUS.DEMO_PAID")
      | {{ $t('demoPaid') }}
  template(
    v-if="data.vip.user.vipStatus === classroomConstants.VIP_STATUS.SHOW &&\
    !beforeAnalysisDeadline &&\
    data.vip.consultantVipAnalysis.filledAt &&\
    isJrClass"
  )
    //- vip此欄位 只放查看的按鈕
    //- 條件: 可以查看評鑑 => 必須是JR的課 且 必須是vipStatus=show & 超過24.5小時 & 有填過評鑑
    //- 顏色: 當初填評鑑的時間是否超過24.5hr 黃=>超過 綠=>時間內填寫
    router-link.btn(
      :to="{\
        name: 'consultant-view-analysis',\
        query: {\
          caId: data.vip.consultantVipAnalysis.caId,\
          vipId: data.vip.vipId,\
        }\
      }"
      :class="[data.vip.consultantVipAnalysis.overdue ? 'btn-warning' : 'btn-success']"
    )
      font-awesome-icon(icon="chart-bar")
    p.filled-at-text {{data.vip.consultantVipAnalysis.filledAt}}
  div
    span.badge.badge-pill(
      :class="getStudentStatus(data.vip.user.vipStatus).class"
    )
      | {{ getStudentStatus(data.vip.user.vipStatus).text }}
  div(v-if="!isJrClass")
    span.text-danger {{ $t('wuwowPlatformFilledOutNotice') }}
    img.smile-face-img.ml-1(:src="smileFaceImg")
</template>
<script>
import moment from 'moment';
import i18n from '@/views/console/consultant/classRecords/vipAnalysisTable/vipField/lang';
import { isDemo } from '@/utils/classroom';
import smileFaceImg from '@/assets/smile-face.png';
import classroomConstants from '@/constants/classroom';
import { mapState } from 'vuex';

export default {
  name: 'VipField',

  i18n,

  props: {
    isJrClass: {
      type: Boolean,
      default: () => (false),
    },
    beforeAnalysisDeadline: {
      type: Boolean,
      default: () => (false),
    },
    data: {
      type: Object,
      default: () => (null),
    },
  },

  data() {
    return {
      classroomConstants,
      smileFaceImg: smileFaceImg,
    };
  },

  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
  },

  methods: {
    isDemo,
    getStudentStatus(vipStatus){
      switch (vipStatus){
        case classroomConstants.VIP_STATUS.NO_SHOW:
          return { class: 'badge-danger', text: this.$t('noShow') };
        case classroomConstants.VIP_STATUS.PROBLEM:
          return { class: 'badge-warning', text: this.$t('problem') };
        case classroomConstants.VIP_STATUS.RETURNED:
          return { class: 'badge-primary', text: this.$t('returned') };
        case classroomConstants.VIP_STATUS.CANCELLED:
          return { class: 'badge-secondary', text: this.$t('cancelled') };
        case classroomConstants.VIP_STATUS.DEMO_CONFIRMED:
          if (this.checkOverClassEvaluationTime(this.data.vip.user.classTime)) {
            return { class: 'badge-secondary', text: this.$t('demoPaid') };
          } else {
            return { class: '', text: '' };
          }
        default:
          return { class: '', text: '' };
      }
    },
    checkOverClassEvaluationTime(time){
      const now = moment(this.currentTime);
      const overTime = moment(time).add(1, 'days').add(30, 'minutes'); // 24.5小時過後
      const isOver = moment(now).isAfter(overTime);
      return isOver;
    },
  },
};
</script>
<style lang="scss" scoped>
.filled-at-text {
  font-size: 14px;
}
</style>
