var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data.vip ? _c('v-template', [_c('span', [_vm._v(_vm._s(_vm.data.vip.user.englishName))]), _c('br'), (_vm.data.vip.vipEvaluation || {}).consultantRating ? _c('div', [_c('router-link', {
    attrs: {
      "to": {
        name: 'consultant-view-evaluation',
        params: {
          evaluationId: _vm.data.vip.vipEvaluation.id
        }
      }
    }
  }, [_c('span', {
    staticClass: "badge badge-pill badge-primary"
  }, [_vm._v(_vm._s(_vm.$t('rating')))]), _c('br'), _c('b-badge', {
    attrs: {
      "variant": _vm.onReturnListVariant(_vm.data.vip.vipEvaluation.consultantRating)
    }
  }, [_vm._v(_vm._s(_vm.data.vip.vipEvaluation.consultantRating))]), _vm.onCheckSuggest(_vm.data.vip.vipEvaluation) ? _c('span', [_c('font-awesome-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "comments",
      "size": "lg"
    }
  })], 1) : _vm._e()], 1)], 1) : _c('div', [_c('span', {
    staticClass: "badge badge-pill badge-danger"
  }, [_vm._v(_vm._s(_vm.$t('noRating')))])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }