export default {
  messages: {
    tw: {
      problemDemoClass: '有問題DEMO',
      demoPaid: 'Demo',
      noShow: '未出席',
      problem: '有其他問題',
      returned: '重進',
      cancelled: '取消',
      wuwowPlatformFilledOutNotice: '請至WUWOW填寫',
    },
    en: {
      problemDemoClass: 'Problem Demo Class',
      demoPaid: 'Demo Paid',
      noShow: 'No show',
      problem: 'Problem',
      returned: 'Returned',
      cancelled: 'Cancelled',
      wuwowPlatformFilledOutNotice: 'Please fill out the report in WUWOW',
    },
  },
};
