var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('good-table', {
    attrs: {
      "mode": "remote",
      "columns": _vm.fields,
      "rows": _vm.rows,
      "totalRows": _vm.totalRows,
      "sort-options": {
        enabled: true,
        multipleColumns: false
      },
      "pagination-options": _vm.options
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'title' ? [_c('span', [_vm._v(_vm._s(row.title))])] : _vm._e(), column.field === 'classType' ? [_c('span', [_vm._v(_vm._s(_vm.$t(`class_type_${row.classType}`)))]), _vm.isShortDemo(row.classType) ? [_c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v("10")])] : _vm.isDemo(row.classType) ? [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.isJrMaterial(row.material.platform) ? '(JR)' : '(W)')
          }
        }), _c('span', {
          staticClass: "badge badge-warning"
        }, [_vm._v("25")])] : _vm._e()] : _vm._e(), column.field === 'status' ? [_c('span', [_vm._v(_vm._s(_vm.getConsultantStatus(row.consultantStatus)))])] : _vm._e(), column.field === 'material' ? [_c('span', [_vm._v(_vm._s(row.material.title))])] : _vm._e(), column.field === 'vip' ? [row.vips && row.vips[0] ? _c('vip-field', {
          attrs: {
            "isJrClass": _vm.isJrClass(row.classType, row.material.platform),
            "data": Object.assign({}, row, {
              vip: row.vips[0]
            }),
            "beforeAnalysisDeadline": _vm.beforeAnalysisDeadline(row.vips[0].classTime)
          }
        }) : _vm._e()] : _vm._e(), column.field === 'operation' ? [(!row.vips[0].consultantVipAnalysis.filledAt || _vm.beforeAnalysisDeadline(row.vips[0].classTime)) && _vm.canWriteByStatus(row) && _vm.isJrClass(row.classType, row.material.platform) ? _c('div', [_c('router-link', {
          staticClass: "btn",
          class: [!row.vips[0].consultantVipAnalysis.filledAt ? 'btn-danger' : 'btn-success'],
          attrs: {
            "to": {
              path: `/console/consultant/classRecords/addAnalysis/${row.classroomId}`
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1) : _vm._e()] : _vm._e()];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }