var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container bg-white rounded-bottom"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col bg-blue rounded-top py-2 mb-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('span', {
    staticClass: "text-white h3"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "list"
    }
  }), _vm._v(" Class Records")], 1)])])]), _c('option-filter', {
    on: {
      "search": _vm.search,
      "reset": _vm.reset
    },
    model: {
      value: _vm.query,
      callback: function ($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _c('server-time-clock'), _c('b-tabs', {
    attrs: {
      "content-class": "mt-3"
    },
    on: {
      "input": _vm.onTabChange
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    staticClass: "border-0",
    attrs: {
      "title": "VIP Analysis"
    }
  }, [_c('vip-analysis-table', {
    ref: "table-analysis",
    attrs: {
      "data": _vm.analysis.data
    },
    on: {
      "load-data": _vm.loadData
    }
  })], 1), _c('b-tab', {
    staticClass: "border-0",
    attrs: {
      "title": "VIP Evaluation"
    }
  }, [_c('vip-evaluation-table', {
    ref: "table-evaluation",
    attrs: {
      "data": _vm.evaluation.data
    },
    on: {
      "load-data": _vm.loadData
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }