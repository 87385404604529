<template lang="pug">
.container.bg-white.rounded-bottom
  .row
    .col.bg-blue.rounded-top.py-2.mb-4
      .d-flex.justify-content-between
        span.text-white.h3
          font-awesome-icon(icon="list")
          |  Class Records
  option-filter(v-model="query" @search="search" @reset="reset")
  server-time-clock
  b-tabs(v-model="tabIndex" @input="onTabChange" content-class="mt-3")
    b-tab.border-0(title="VIP Analysis")
      vip-analysis-table(
        ref="table-analysis"
        :data="analysis.data"
        @load-data="loadData"
      )
    b-tab.border-0(title="VIP Evaluation")
      vip-evaluation-table(
        ref="table-evaluation"
        :data="evaluation.data"
        @load-data="loadData"
      )
</template>

<script>
import optionFilter from '@/views/console/consultant/classRecords/optionFilter/index.vue';
import vipAnalysisTable from '@/views/console/consultant/classRecords/vipAnalysisTable/index.vue';
import vipEvaluationTable from '@/views/console/consultant/classRecords/vipEvaluationTable/index.vue';
import wuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import serverTimeClock from '@/components/serverTimeClock';

import api from '@lioshutan/api-package';
import moment from 'moment';

const DEFAULT_PER_PAGE = 10, FIRST_PAGE = 1, ZERO = 0;

export default {
  components: {
    optionFilter,
    vipAnalysisTable,
    vipEvaluationTable,
    serverTimeClock,
  },
  mixins: [wuwowLoadingMixin],
  data: () => ({
    loading: null,
    token: '',
    query: {
      name: null,
      time: {
        start: null,
        end: null,
      },
      unfilled: null,
    },
    analysis: {
      data: null,
      pagination: {
        page: FIRST_PAGE,
        perPage: DEFAULT_PER_PAGE,
        total: ZERO,
        sort: null,
      },
    },
    evaluation: {
      data: null,
      pagination: {
        page: FIRST_PAGE,
        perPage: DEFAULT_PER_PAGE,
        total: ZERO,
        sort: null,
      },
    },
    tabIndex: 0,
  }),
  computed: {
    tableTarget() {
      return ['analysis', 'evaluation'][this.tabIndex];
    },
  },
  created() {
    this.token = this.$store.state.auth.token;
  },
  async mounted() {
    this[this.tableTarget]['pagination'] = this.$refs[`table-${this.tableTarget}`]['serverParams'];
    await this.loadData(this[this.tableTarget]['pagination']);
  },
  methods: {
    async loadData(params) {
      this[this.tableTarget]['pagination'] = params;

      const tag = this.startLoading();
      try {
        const response = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .classroom(this.token)
          .getConsultantClassroom({
            ...this[this.tableTarget]['pagination'],
            action: 'history',
            vipName: this.query.name,
            startDateTime: moment(this.query.time.start).format('YYYY-MM-DD HH:mm:ss'),
            endDateTime: moment(this.query.time.end).format('YYYY-MM-DD HH:mm:ss'),
            unfilledAnalyses: this.query.unfilled,
          });
        this[this.tableTarget]['data'] = response;
        this.endLoading(tag);
      } catch (error) {
        this.endLoading(tag);
        await alertFailedMessage('Please contact support:<br>65b40a8f-6880-4688-ba7d-004b086caad0');
      }
    },
    onTabChange() {
      this.loadData(this[this.tableTarget]['pagination']);
    },
    search(params) {
      this.query = params;
      this.loadData(this[this.tableTarget]['pagination']);
    },
    reset(query) {
      this.query = query;
      this.loadData(this[this.tableTarget]['pagination']);
    },
  },
};
</script>

<style lang="scss">
.tab-content {
  border: 0;
}
</style>
