export default {
  messages: {
    tw: {
      rating: '評分',
      noRating: '未評分',
    },
    en: {
      rating: 'Rating',
      noRating: 'No rating',
    },
  },
};
