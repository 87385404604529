<template lang="pug">
good-table(
  mode="remote"
  @on-page-change="onPageChange"
  @on-sort-change="onSortChange"
  :columns="fields"
  :rows="rows"
  :totalRows="totalRows"
  :sort-options="{ enabled: true, multipleColumns: false, }"
  :pagination-options="options"
)
  template(slot="table-row" slot-scope="{ column, row }")
    template(v-if="column.field === 'title'")
      span {{ row.title }}
    template(v-if="column.field === 'classType'")
      span {{ $t(`class_type_${row.classType}`) }}
      template(v-if="isShortDemo(row.classType)")
        //- 10分鐘的短DEMO
        span.badge.badge-danger 10
      template(v-else-if="isDemo(row.classType)")
        //- 目前 platform 暫時先用教材
        span(v-html="isJrMaterial(row.material.platform)? '(JR)': '(W)'")
        //- 25分鐘的DEMO
        span.badge.badge-warning 25
    template(v-if="column.field === 'status'")
      span {{ getConsultantStatus(row.consultantStatus) }}
    template(v-if="column.field === 'material'")
      span {{ row.material.title }}
    template(v-if="column.field === 'vip'")
      vip-feedback(v-if="row.vips && row.vips[0]" :data="{ ...row, vip: row.vips[0] }")
    //- 20220613移除vip abc
      template(v-if="column.field === 'vipA'")
        vip-feedback(v-if="row.vips && row.vips[0]" :data="{ ...row, vip: row.vips[0] }")
      template(v-if="column.field === 'vipB'")
        vip-feedback(v-if="row.vips && row.vips[1]" :data="{ ...row, vip: row.vips[1] }")
      template(v-if="column.field === 'vipC'")
        vip-feedback(v-if="row.vips && row.vips[2]" :data="{ ...row, vip: row.vips[2] }")
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable as GoodTable } from 'vue-good-table/dist/vue-good-table';
import vipFeedback from '@/views/console/consultant/classRecords/vipEvaluationTable/vipFeedbackField/index.vue';
import classroomConstants from '@/constants/classroom';
import { isShortDemo, isDemo, isJrMaterial } from '@/utils/classroom';
import fields from '@/views/console/consultant/classRecords/vipEvaluationTable/fields';
import i18n from '@/views/console/consultant/classRecords/vipEvaluationTable/lang';

const DEFAULT_PER_PAGE = 10,
      FIRST_PAGE = 1,
      HIGH_RATE = 80,
      MEDIUM_RATE = 60;

export default {
  components: {
    GoodTable,
    vipFeedback,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  i18n,
  data: () => ({
    fields,
    options: {
      enabled: true,
      mode: 'pages',
      perPage: DEFAULT_PER_PAGE,
      perPageDropdownEnabled: false,
      jumpFirstOrLast: true,
    },
    serverParams: {
      page: FIRST_PAGE,
      perPage: DEFAULT_PER_PAGE,
      sort: null,
    },
  }),

  computed: {
    rows() {
      if (!this.data) {
        return [];
      }
      return this.data.data.classRecords;
    },
    totalRows() {
      if (!this.data) {
        return 0;
      }
      return this.data.meta.pagination.total;
    },
  },
  methods: {
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.loadItems();
    },
    onSortChange(params) {
      // 取得第一個排序
      const sort = [...params].shift();
      this.serverParams.sort = `${sort.field}|${sort.type}`;

      if (sort.type === 'none') {
        this.serverParams.sort = null;
      }
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      const serverParams = Object.assign({}, {
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        sort: this.serverParams.sort,
      });
      this.$emit('load-data', serverParams);
    },
    onReturnListVariant(rating) {
      if (rating >= HIGH_RATE) {
        return 'success'; // bootstrap 綠色 class
      } else if (rating >= MEDIUM_RATE) {
        return 'warning'; // bootstrap 黃色 class
      } else {
        return 'danger'; // bootstrap 紅色 class
      }
    },
    onCheckSuggest(data) {
      if (data.hbSuggestion ||
        (
          data.suggestionExpressTimeRating &&
          data.suggestionExpressTimeRating !== '0'
        ) ||
        data.suggestionOverallTimeRating ||
        data.suggestionAdviceRating
      ) {
        return true;
      } else {
        return false;
      }
    },
    getConsultantStatus(status){
      switch (status){
        case classroomConstants.CONSULTANT_STATUS.NO_SHOW:
          return this.$t('noShow');
        case classroomConstants.CONSULTANT_STATUS.SHOW:
          return this.$t('show');
        case classroomConstants.CONSULTANT_STATUS.LATE:
          return this.$t('late');
        case classroomConstants.CONSULTANT_STATUS.TARDY:
          return this.$t('tardy');
        case classroomConstants.CONSULTANT_STATUS.PROBLEM:
          return this.$t('problem');
        default:
          return '-';
      }
    },
    isShortDemo,
    isDemo,
    isJrMaterial,
  },
};
</script>

<style lang="scss">
.vgt-global-search {
  border: 0;
  background: #fff;
  max-width: 100%;
  .vgt-global-search__input {
    max-width: 65%;
    width: 100%;
  }
  input {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 2px solid #ddd;
    border-radius: 0;
  }
  .input-field {
    border-bottom: 2px solid #ddd;
    margin: 0 0;
    width: 100%;
  }
  .vgt-global-search__actions {
    max-width: 25%;
    width: 100%;
  }
  select {
    -webkit-appearance: none;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    font-size: 14px;
    display: inline-block;
    max-width: 85%;
    width: 100%;
    option {
      color: #999;
    }
  }
}

.vgt-table.bordered {
  border: 0;
  tr.clickable {
    border-bottom: 1px solid #ddd;
    &:hover {
      background-color: #F5F5F5;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  thead {
    th {
      background: #fff;
    }
  }
  td {
    border-left: 0;
    border-right: 0;
    padding: 20px;
  }
  th {
    border: 0;
  }
  th.vgt-checkbox-col {
    background: #fff;
    input[type="checkbox"] {
      width: 22px;
      height: 22px;
      border: 2px solid #888;
      border-radius: 2px;
      -webkit-appearance: none;
      cursor: pointer;
      position: relative;
      display: inline-block;
      &:checked {
        background-color: #757575;
        border-color: #363636;
        &:after {
          content: '';
          display: block;
          width: 6px;
          height: 12px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          left: 25%;
        }
      }
    }
  }
}

.vgt-wrap__actions-footer, .vgt-wrap__footer {
  border: 0;
}
</style>
