<template lang="pug">
.row
  .col-md-4
    .form-group
      label Class Start Time
      input.form-control.w-100.d-block(
        v-model="query.time.start"
        type="datetime-local"
        lang="en"
        value-type="format"
        @input="change"
      )
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label Class End Time
      input.form-control.w-100.d-block(
        v-model="query.time.end"
        type="datetime-local"
        lang="en"
        value-type="format"
        @input="change"
      )
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label Unfilled Analyses
      select.form-control(v-model="query.unfilled" @input="change")
        option(:value="null" selected) Select option
        option(:value="true") Yes
        option(:value="false") No
      small.form-text.text-muted(
        v-if="false"
      ) GG
  .col-md-4
    .form-group
      label VIP
      b-input(
        v-model="query.name"
        placeholder="Enter VIP's name"
        @input="change"
      )
  .col-md-4
    .form-group
      label.invisible Action
      div
        button.btn.btn-primary.mr-2(@click="search") Search
        button.btn.btn-default(@click="reset") Reset
      small.form-text.text-muted(
        v-if="false"
      ) GG
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const FILTER_DEFAULT_PERIOD_DAY = 7;

export default {
  components: {
    multiselect,
    datePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        time: {
          start: null,
          end: null,
        },
        name: null,
        unfilled: null,
      }),
    },
  },
  data: () => ({
    query: {
      time: {
        start: null,
        end: null,
        // start: '2021-05-01 00:00:00',
        // end: '2021-05-31 00:00:00',
      },
      name: null,
      unfilled: null,
    },
  }),
  created() {
    this.change();
    this.setDefautDatePeriod();
  },
  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
  },
  methods: {
    search() {
      const searchData = {
        ...this.query,
        unfilled: this.query.unfilled ? 1 : 0,
      };
      this.$emit('search', searchData);
    },
    reset() {
      this.query = {
        time: {
          start: null,
          end: null,
        },
        name: null,
        unfilled: null,
      };
      this.setDefautDatePeriod();
      this.change();
      this.$emit('reset', this.query);
    },
    change() {
      this.$emit('input', this.query);
    },
    setDefautDatePeriod(){
      const timeFormat = 'YYYY-MM-DD HH:mm:ss';
      this.query.time.start = (moment().subtract(FILTER_DEFAULT_PERIOD_DAY, 'days').format(timeFormat));
      this.query.time.end = (moment().format(timeFormat));
    },
  },
};
</script>
