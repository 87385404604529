<template lang="pug">
v-template(v-if="data.vip")
  span {{ data.vip.user.englishName }}
  br
  div(v-if="(data.vip.vipEvaluation || {}).consultantRating")
    router-link(
      :to="{\
        name: 'consultant-view-evaluation',\
        params: { evaluationId: data.vip.vipEvaluation.id }\
      }"
    )
      span.badge.badge-pill.badge-primary {{ $t('rating') }}
      br
      b-badge(:variant="onReturnListVariant(data.vip.vipEvaluation.consultantRating)")
        | {{ data.vip.vipEvaluation.consultantRating }}
      span(v-if="onCheckSuggest(data.vip.vipEvaluation)")
        font-awesome-icon.ml-2(icon="comments" size="lg")
  div(v-else)
    span.badge.badge-pill.badge-danger {{ $t('noRating') }}
</template>

<script>
import i18n from '@/views/console/consultant/classRecords/vipEvaluationTable/vipFeedbackField/lang';
import { Fragment as VTemplate } from 'vue-fragment';

export default {
  name: 'VipFeedbackField',

  components: {
    VTemplate,
  },

  i18n,

  props: {
    data: {
      type: Object,
      default: () => (null),
    },
  },

  methods: {
    onRedirectEvaluation(vip, hb_name, material_title) {
      // TODO: 切換頁面
      console.log([vip, hb_name, material_title]);
    },
    onCheckSuggest(data) {
      return data.hbSuggestion ||
        (data.suggestionExpressTimeRating && data.suggestionExpressTimeRating !== '0') ||
        data.suggestionOverallTimeRating ||
        data.suggestionAdviceRating;
    },
    onReturnListVariant(rating) {
      if (rating >= 80) {
        return 'success';
      } else if (rating >= 60) {
        return 'info';
      } else if (rating >= 40) {
        return 'warning';
      } else {
        return 'danger';
      }
    },
  },
};
</script>
